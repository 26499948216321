<template>
<v-card color="#F0FFFF" elevation="0" outlined min-width="250" max-width="250" height="280">
  <v-container fluid>
    
      <div class="d-flex px-10 py-2 align-center">
        <v-icon @click="prevYear">mdi-arrow-left-bold</v-icon>
        <v-spacer></v-spacer>
        <div v-if="pickedMonth" class="text-subtitle-2">{{ pickedMonth.year }}年</div>
        <v-spacer></v-spacer>
        <v-icon @click="nextYear">mdi-arrow-right-bold</v-icon>
      </div>
      <div v-if="!!months" class="pa-1" v-for="i in [0,1,2,3]" :key="i">
        <div class="d-flex my-1">
          <v-btn @click="pickMonth(months[i*3+j].value)" small :color="getColor(months[i*3+j].value)" class="mx-1" width="60" :key="j" v-for="j in [0,1,2]" elevation="0">
            <div class="text-caption">{{ months[i*3+j].text }}</div>
          </v-btn>
        </div>
      </div>
    <div class="d-flex justify-center mt-5">
      <div class="align-center d-flex">
        <v-avatar size="10" color="#80CBC4"></v-avatar>
        <div class="text-caption mx-2">已交</div>
      </div>
      <div class="align-center d-flex">
        <v-avatar size="10" color="#FFCDD2"></v-avatar>
        <div class="text-caption mx-2">未交</div>
      </div>
      <div class="align-center d-flex">
        <v-avatar size="10" color="#9575CD"></v-avatar>
        <div class="text-caption mx-2">补交</div>
      </div>
    </div>
  </v-container>
</v-card>
</template>

<script>
export default {
  name: 'MonthCalendar',
  props: ['current', 'actives', 'expireds'],
  data() {
    return {
      pickedMonth: null,
      months: null
    }
  },
  methods: {
    getColor(value) {
      const date = new Date(this.pickedMonth.year, value - 1, 1).getTime();
      const today = new Date().getTime();
      if (date > today) {
        return '';
      } else if (!!this.actives) {
        const month = `${this.pickedMonth.year}-${value}`;
        if (this.actives.includes(month)) {
          return '#66CDAA';
        } else if (this.expireds.includes(month)) {
          return '#7B68EE';
        }
      }
      return '#F08080';
    },
    pickMonth(value) {
      this.pickedMonth.month = value;
      this.$emit('pick', this.pickedMonth);
    },
    prevYear() {
      this.pickedMonth.year -= 1;
      this.pickedMonth.month = 1;
      this.$emit('pick', this.pickedMonth);
    },
    nextYear() {
      this.pickedMonth.year += 1;
      this.pickedMonth.month = 1;
      this.$emit('pick', this.pickedMonth);
    }
  },
  mounted() {
    this.pickedMonth = Object.assign({}, this.current);
    this.months = [];
    for (let i = 0; i < 12; i++) {
      this.months.push({
        text: `${i+1}月`,
        value: i + 1
      })
    }
  }
}
</script>
